document.addEventListener('DOMContentLoaded', () => {
  const carouselWrapper = document.querySelector('.carousel-wrapper');
  const leftArrow = document.querySelector('.left-arrow');
  const rightArrow = document.querySelector('.right-arrow');
  const dots = document.querySelectorAll('.mobile-dot');
  const contributorWrappers = document.querySelectorAll('.contributor-wrapper');
  const contributorWrapperWidth = contributorWrappers[0].offsetWidth;
  const visibleDots = Math.floor(carouselWrapper.offsetWidth / contributorWrapperWidth);
  const offset = Math.floor(visibleDots / 2);

  leftArrow.addEventListener('click', () => {
    carouselWrapper.scrollLeft -= contributorWrapperWidth;
    updateArrowColor();
  });

  rightArrow.addEventListener('click', () => {
    carouselWrapper.scrollLeft += contributorWrapperWidth;
    updateArrowColor();
  });

  carouselWrapper.addEventListener('scroll', () => {
    const scrollPosition = carouselWrapper.scrollLeft;
    const currentIndex = Math.floor(scrollPosition / contributorWrapperWidth);
    updateActiveDot(currentIndex);
    updateArrowColor();
  });

  dots.forEach((dot, index) => {
    dot.addEventListener('click', () => {
      const selectedContributor = contributorWrappers[index];
      const scrollPosition = selectedContributor.offsetLeft;
      carouselWrapper.scrollTo({
        left: scrollPosition,
        behavior: 'smooth'
      });

      updateActiveDot(index);
      updateArrowColor();
    });
  });

  let updateActiveDot = (index) => {
    dots.forEach((dot, dotIndex) => {
      if (dotIndex === index) {
        dot.classList.add('active');
      } else {
        dot.classList.remove('active');
      }
    });
  };

  let updateArrowColor = () => {
    const maxScrollLeft = carouselWrapper.scrollWidth - carouselWrapper.clientWidth;
    if (carouselWrapper.scrollLeft >= maxScrollLeft) {
      rightArrow.querySelector('path').setAttribute('stroke', '#A1A1AA');
    } else {
      rightArrow.querySelector('path').setAttribute('stroke', '#E22944');
    }
    if (carouselWrapper.scrollLeft === 0) {
      leftArrow.querySelector('path').setAttribute('stroke', '#A1A1AA');
        } else {
          leftArrow.querySelector('path').setAttribute('stroke', '#E22944');
        }
  };

  leftArrow.addEventListener('click', () => {
    carouselWrapper.scrollLeft -= postCardWidth;
    updateArrowColor();
  });
  
  rightArrow.addEventListener('click', () => {
    carouselWrapper.scrollLeft += postCardWidth;
    updateArrowColor();
  });

  // Initial active dot
  const initialScrollPosition = carouselWrapper.scrollLeft;
  const initialIndex = Math.floor(initialScrollPosition / contributorWrapperWidth);
  updateActiveDot(initialIndex);
  updateArrowColor();
});